import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';

export const firstUpperAndUpperOrNumber = /^[A-Z]([A-Z0-9])?$/;

export const onlyLetterOrNumber = /^[a-zA-Z0-9]+$/;

export const verifyOtherFieldHasValue = (
  form: AbstractControl,
  inputKey: string,
  anotherKey: string,
  validatorKey: string,
): ValidationErrors | null => {
  const firstInput = form.value[inputKey];
  const anotherInput = form.value[anotherKey];
  if (anotherInput && !firstInput) {
    return { [validatorKey]: true };
  }
  return null;
};

interface CustomErrorMessages {
  required?: string;
  pattern?: string;
}

export const getErrorMessage = (
  formControl: FormControl,
  name: string,
  message?: CustomErrorMessages,
) => {
  if (formControl.hasError('required') && name === 'User type') {
    return `Please select an option`;
  }

  if (formControl.hasError('required')) {
    return message?.required ?? `${name} is required`;
  }

  if (formControl.hasError('maxlength')) {
    return `${name} must be up to ${formControl.errors?.maxlength.requiredLength} characters long`;
  }

  if (formControl.hasError('pattern')) {
    return message?.pattern ?? `Invalid value for ${name}`;
  }

  if (formControl.hasError('email')) {
    return `A valid E-mail address must be provided.`;
  }

  return;
};

export const parseStringIfEmpty = (item: string | null | undefined) => {
  return item || null;
};
